@font-face {
    font-family: "ProximaNova-Semibold";
    src: local("ProximaNova-Semibold"),
        url("./fonts/proximanova-semibold-webfont.woff2") format("woff2"),
        url("./fonts/proximanova-semibold-webfont.woff") format("woff"),
        url("./fonts/ProximaNova-Semibold.otf") format("opentype");
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "ProximaNova-Light";
    src: local("ProximaNova-Light"),
        url("./fonts/proximanova-light-webfont.woff2") format("woff2"),
        url("./fonts/proximanova-light-webfont.woff") format("woff"),
        url("./fonts/ProximaNova-Light.otf") format("opentype");
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "ProximaNova-Regular";
    src: local("ProximaNova-Regular"),
        url("./fonts/proximanova-regular-webfont.woff2") format("woff2"),
        url("./fonts/proximanova-regular-webfont.woff") format("woff"),
        url("./fonts/ProximaNova-Regular.otf") format("opentype");
    font-weight: normal;
    font-display: swap;
}
@font-face {
    font-family: "ProximaNova-Bold";
    src: local("ProximaNova-Bold"),
        url("./fonts/proximanova-bold-webfont.woff2") format("woff2"),
        url("./fonts/proximanova-bold-webfont.woff") format("woff"),
        url("./fonts/ProximaNova-Bold.otf") format("opentype");
    font-weight: normal;
    font-display: swap;
}
