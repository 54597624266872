#avb-application-details {
    height: 100vh;
    .admin-body {
        background-color: white;
        padding: 0 50px;
        height: 100%;
    }

    .row.heading {
        font-family: "ProximaNova-Bold";
        font-size: 30px;
        color: #31343a;
        padding: 33px 0 25px 0;
    }

    .row.sections {
        .left-panel {
            background-color: var(--pale-grey-bg-color);
            color: #31343a;
            text-align: left;
            padding-left: 2%;
            margin-bottom: 16px;
            .heading {
                margin: 34px 0;
                font-size: 16px;
                font-family: "ProximaNova-Semibold";
            }
            .label {
                font-size: 14px;
                margin-bottom: 8px;
                font-family: "ProximaNova-Light";
            }
            .text {
                font-size: 16px;
                margin-bottom: 28px;
                font-family: "ProximaNova-Regular";
            }
        }
        @media screen and (max-width: 768px) {
            .left-panel {
                padding-left: 15%;
            }
        }
    }
    .ready-to-finish-notification {
        padding: 1rem;
        background: #e6f7ff;
        border: solid 1px #91d5ff;
        border-radius: 6px;
        display: flex;
        flex: 100%;
        flex-direction: row;
        margin-bottom: 10px;
        .msg {
            margin-left: 1rem;
            flex: 1;
            text-align: left;
        }
        .finish-application {
            border: none;
            font-size: 14px;
            background: #fff;
            border-radius: 4px;
            font-weight: bold;
        }
        svg {
            margin-top: 4px;
        }
    }
}
